<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="reset"
            @click="doReset"
        />
        <b-input
            v-model="xDesc"
            class="row-1 text-center my-3"
            placeholder="x  Variable Desc. (optional)"
        />
        <b-input
            v-model="yDesc"
            class="row-1 text-center my-3"
            placeholder="y  Variable Desc. (optional)"
        />

        <h5 class="my-3 text-center">
            Input x and y pairs
        </h5>

        <div
            v-for="(item,index) in items"
            :key="index"
            class="input-group"
        >
            <div><label class="form-control list-id"> {{ index + 1 + "." }} </label></div>
            <input
                ref="refX"
                v-model="item.inputValueX"
                class="form-control text"
                placeholder="X"
                @focus="onFocus(index)"
                @keyup.enter="onSubmitEdit(item, index, $event.target)"
            >
            <input
                ref="refY"
                v-model="item.inputValueY"
                class="form-control text"
                placeholder="Y"
                @focus="onFocus(index)"
                @keyup.enter="onSubmitEdit(item, index, $event.target)"
            >
            <a
                v-show="items.length !== 1"
                class="mt-1 ml-2"
                role="button"
                @click="removeItem(index)"
            ><i class="bi bi-dash-circle-fill" /></a>
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="btn btn-success w-25 m-2"
                @click="addItem()"
            >
                Add Row
            </button>
        </div>

        <div class="sticky-bottom m-3">
            <button
                class="action-btn btn-block"
                block
                @click="calculatePressed()"
            >
                Calculate
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import rData from '@/js/regression/regression-data.js'
import sampleData from '@/js/lib/sample-data.js'
import _ from 'lodash'

export default {
    name: 'Regression',
    components: {
        TopNavigationBar
    },
    data () {
        return {
            xDesc: '',
            yDesc: '',
            currentID: 0,
            items: [
                { inputValueX: '', inputValueY: '' },
            ],
            // Testing
            isTesting: false,
            Xtest: sampleData.regression.case2.x,
            Ytest: sampleData.regression.case2.y,
        }
    },
    beforeMount () {
        var store = localStore.getStore('regression-data')
        if (store.items) {
            this.items = store.items
            this.xDesc = store.xDesc
            this.yDesc = store.yDesc
        }
    },
    methods: {
        doReset () {
            this.items = [
                { inputValueX: '', inputValueY: '' },
            ]
            this.xDesc = ''
            this.yDesc = ''
        },
        addItem () {
            this.items.push({ inputValueX: '', inputValueY: '' })
        },
        removeItem (index) {
            if (index !== -1) {
                this.items.splice(index, 1)
            }
            this.$refs.refX[index].focus()
        },
        onFocus (index) {
            this.currentID = index
        },
        onSubmitEdit (item, index, event) {
            var { inputValueX, inputValueY } = item
            const nextRow = index + 1

            // Next column focus
            if (inputValueY === '') {
                event.nextElementSibling.focus()
                return
            } else if (inputValueX === '') {
                event.previousElementSibling.focus()
                return
            }
            // Next row focus
            if (nextRow !== this.items.length) {
                this.$refs.refX[nextRow].focus()
                return
            }
            if (inputValueY && inputValueX) {
                this.addItem()
                this.$nextTick(() => {
                    this.$refs.refX[nextRow].focus()
                })
            }
        },
        calculatePressed () {
            var data = this.items

            if (this.isTesting) {
                data = rData.formatTestData(this.Xtest, this.Ytest)
            }
            var formattedData = rData.formatDataCheck(data)

            if (!_.isEmpty(formattedData.errors)) {
                alert(_.head(formattedData.errors))
            } else {
                const xDesc = !_.trim(this.xDesc) ? 'x Axis' : this.xDesc
                const yDesc = !_.trim(this.yDesc) ? 'y Axis' : this.yDesc

                localStore.setStoreItem('regression-data', { items: formattedData.output, xDesc, yDesc })
                this.$router.push({ name: 'regression/table-output' })
            }
        }
    },
}
</script>
